// pages
import Home from "./layouts/home/Home";
import Aboutus from "./layouts/aboutus/Aboutus";
import Shop from "./layouts/shop/Shop";
import {FcHome, FcShop, FcBusinessman} from "react-icons/fc";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: <FcHome />,
    route: "/Home",
    component: <Home />,
  },
  {
    type: "collapse",
    name: "About us",
    key: "aboutus",
    icon: <FcBusinessman />,
    route: "/Aboutus",
    component: <Aboutus/>,
  },
  {
    type: "collapse",
    name: "Shop",
    key: "shop",
    icon: <FcShop />,
   
    route: "/Shop",
    component: <Shop />,
  },
  
];

export default routes;
