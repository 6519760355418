import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import Container from "@mui/material/Container";

import Button from "@mui/material/Button";

import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import routes from "../routes";

import BottomAppBar from "./menu/BottomAppBar";

function ResponsiveAppBar() {
  const [, setAnchorElNav] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" sx={{ background: "#f5f5f5" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/Home"
            sx={{
              mr: 1,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 1000,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={logo} alt="logo" style={{ height: "100px" }} />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <BottomAppBar />
          </Box>
          <Typography
            variant="h2"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 1,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 1000,
              letterSpacing: "1.2rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={logo} alt="rk products logo" style={{ height: "70px" }} />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              marginRight: "60px", // Shift the buttons somewhat to the left
            }}
          >
            {routes.map((route, index) => (
              <Button
                key={route.key}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "#6c6666",
                  display: "block",
                  fontWeight: "bold", // Make the text bold
                  fontSize: "1.2rem", // Increase font size
                  marginRight: index !== routes.length - 1 ? "20px" : 0, // Add margin to all buttons except the last one
                }}
              >
                <IconButton color="inherit" aria-label="icon" component="label">
                  {route.icon}
                </IconButton>

                <Link style={{ textDecoration: "none", color: "black" }} to={route.route}>
                  {route.name}
                </Link>
              </Button>
            ))}
          </Box>
          
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
