import { Route, Routes } from "react-router-dom";
import "./App.css";
import ResponsiveAppBar from "./components/Nav";
import Home from "./layouts/home/Home";
import Aboutus from "./layouts/aboutus/Aboutus";
import Shop from "./layouts/shop/Shop";

function App() {
  return (
    <div className="App">
      <ResponsiveAppBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Aboutus" element={<Aboutus />} />
        <Route path="/Shop" element={<Shop />} />
        
      </Routes>
    
    </div>
  );
}

export default App;
