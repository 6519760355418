// import React from 'react';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import './Home.scss'; 

// import { Grid } from '@mui/material';

import rkbg from './images/RK bgpic.png';
import one from './images/one.png';
import two from './images/two.png';
import three from './images/three.png';
import four from './images/Four.png';

// const images = [rkbg, one, four, two, three];

// const Home = () => {
//   return (
    
//         <Carousel autoPlay infiniteLoop showStatus={false}>
//           {images.map((image, index) => (
//             <div key={index} className="slide-item">
//               <img src={image} alt={`Slide ${index + 1}`} className="carousel-image" />
//             </div>
//           ))}
//         </Carousel>
     
//   );
// }

// export default Home;



import React from 'react';
// import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';



const Home = () => {
  return (
    
    <Carousel>
        <div>
            <img src={rkbg} />
        </div>
        <div>
            <img src={one} />
        </div>
        <div>
            <img src={two} />
        </div>
        <div>
            <img src={three} />
        </div>
        <div>
            <img src={four} />
        </div>
    </Carousel>

  )
}

export default Home

