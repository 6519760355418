import React from 'react';
import { Grid } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Aboutus.scss';


import aboutone from './images/aboutone.jpg';
import abouttwo from './images/abouttwo.jpg';
import aboutthree from './images/aboutthree.jpg';
import aboutfour from './images/aboutfour.jpg';
import aboutfive from './images/aboutfive.jpg';
import aboutsix from './images/aboutsix.jpg';
const images = [aboutone, abouttwo, aboutthree, aboutfour , aboutfive,aboutsix];

const LeftGridContent = () => {
  return (
    <div style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>
      <p>Welcome to R K Hair Products Pvt Ltd, a trailblazer in the hair industry since 1990. As the largest exporter of raw human hair and human hair products, we take pride in our status as a government-recognized 2-star export house. With over 500 dedicated employees, all exclusively women, we strive for excellence in every aspect of our business.</p>
      <br />
      <p>Our commitment to quality and innovation has made us pioneers in the industry, exporting to over 70 countries worldwide. From raw human hair to a wide range of meticulously crafted hair products, our offerings cater to diverse needs and preferences.</p>
      <br />
      <p>At R K Hair Products, customer satisfaction is paramount. Our impeccable service and dedication to meeting customer expectations have earned us multiple awards from both government bodies and prestigious institutions.</p>
      <br />
      <p>Join us in our journey to redefine beauty and confidence through exceptional hair products. Experience the difference with R K Hair Products Pvt Ltd.</p>
      <br />
    </div>
  );
};

function Aboutus() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    swipeToSlide: true,
    edgeFriction: 0.15,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set autoplay speed in milliseconds (e.g., 2000ms = 2 seconds)
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false, // Disable variable width on mobile
        }
      }]
  };


  return (
    <div className="aboutus-container"> {/* Added a class for styling */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} order={{ xs: 2, sm: 1 }}>
          <LeftGridContent />
        </Grid>
        <Grid item xs={12} sm={6} order={{ xs: 1, sm: 2 }}>
          <Slider {...settings} className="carousel-container">
            {images.map((image, index) => (
              <div key={index} className="slide-item">
                <img src={image} alt={`Slide ${index + 1}`} className="carousel-image" />
              </div>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </div>
  );
}

export default Aboutus;
