import React, { useState,useEffect,useRef } from 'react';
import { Grid, Drawer,useMediaQuery ,Typography, TextField } from '@mui/material';
import rk from './data/Rk_Data.json';
import { FaShoppingCart,FaTable,FaTimes } from 'react-icons/fa';
import emailjs from 'emailjs-com';

import'./Shop.scss';
//import 'bootstrap/dist/css/bootstrap.min.css'; 
import { TableContainer, Table, TableHead,TableBody, TableRow, TableCell, Button, Dialog, DialogTitle, DialogContent, DialogActions, } from '@mui/material'
import Machineweft from './images/products/Machineweft.png';
import Handweft from './images/products/HAND WEFT.png';
import ClipOn7Pieces from './images/products/7 piece clipset.png';
import ClipOn10Pieces from './images/products/10 PCS CLIP SET.png';
import PreBond from './images/products/PRE-BONDS.png';
import Halo from './images/products/halohair.png';
import MachineWig from './images/products/machine wig.png';
import Closure from './images/products/closure.png';
import Frontal from './images/products/frontal.png';
import FrontLaceWig from './images/products/frontlacewig.png';
//import Bulk from './images/products/bulk.jpg';

const images = [
  { id: 0, image: Machineweft, text: 'MACHINE WEFT' },
  { id: 1, image: Handweft, text: 'HAND WEFT' },
  { id: 2, image: ClipOn7Pieces, text: 'CLIP ON MW 7 PIECES' },
  { id: 3, image: ClipOn10Pieces, text: 'CLIP ON HW 10 PIECES' },
  { id: 4, image: PreBond, text: 'PRE BONDS' },
  { id: 10, image: Halo, text: 'HALO' },
  { id: 13, image: MachineWig, text: 'MACHINE WIGS' },
  { id: 14, image: Closure, text: 'CLOSURES' },
  { id: 15, image: Frontal, text: 'FRONTAL' },
  { id: 16, image: FrontLaceWig, text: 'FRONT LACE WIG' },
  //{ id: 16, image: Bulk, text: 'BULK' },

];

const Shop = () => {
 
  const [selectedColor, setSelectedColor] = useState("Natural"); // Initially select Natural color
  const [selectedProduct, setSelectedProduct] = useState("MACHINE WEFT"); // Initially select MACHINE WEFT product
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedStructure, setSelectedStructure] = useState("STRAIGHT");
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [cartItems, setCartItems] = useState([]);
  const [uniqueProducts, setUniqueProducts] = useState([]);
  const [uniqueSizes, setUniqueSizes] = useState([]);
  const [uniqueStructures, setUniqueStructures] = useState([]);
 
  const [sizeQuantities, setSizeQuantities] = useState({});
  const [showCart, setShowCart] = useState(false);
  const [,setGrandTotal] = useState({});
  
  const [name, setName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [email, setEmail] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const [showSecondGrid, setShowSecondGrid] = useState(false);
  const secondGridRef = useRef(null);

  useEffect(() => {
    handleColorChange(selectedColor);
  }, []);
  

  const handleToggleDrawer = () => {
    
    if (cartItems.length === 0) {
      // Display alert message
     
      setShowDrawer(!showDrawer);
      alert('Your cart is empty. Please add products from the list.');
    } else {
      // Toggle drawer
      setShowDrawer(!showDrawer);
    }
  };
  const handleCheckout = () => {
    setShowCart(false);
    setShowCheckoutForm(true);
  };


  
  const handleContinueShopping = () => {
    handleToggleDrawer();
  };
  
    

  const handleConfirmCheckout = () => {
    // Form validation
    if (name.trim() === '') {
        alert('Please enter your name.');
        return;
    }
    if (!/^\d{10}$/.test(contactNumber)) {
        alert('Please enter a valid 10-digit phone number.');
        return;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        alert('Please enter a valid email address.');
        return;
    }

 const totalPrice = getTotalPrice();
 const emailData = {
  name,
  contactNumber,
  email,
  cartItems: formatCartItemsAsPlainText(cartItems, totalPrice, name, contactNumber, email),
};
   
    sendEmail(emailData);
 
     // Display alert when email is sent successfully
    alert('Thankyou for shopping! your email sent successfully');
   
   
      
    
   };
    //console.log(cartItems,'gsavdghvsadh')
    const formatCartItemsAsPlainText = (cartItems, totalPrice, name, contactNumber, email) => {
    let plainText = '';
    plainText += `Name: ${name}\n`;
    plainText += `Contact Number: ${contactNumber}\n`;
    plainText += `Email: ${email}\n\n`;
    cartItems.forEach((item) => {
       plainText += `Product: ${item.product}\nColor: ${item.color}\nNetWeight: ${item.netWeight}\nSize: ${item.size}\nStructure: ${item.structure}\nQuantity: ${item.quantity}\nTotalNetWeight: ${item.totalWeight}\nPrice: ${item.totalPrice}\n\n`;
    });
    plainText += `Total Price: ${totalPrice}`;

     return plainText;
   };
   
 
const sendEmail = (emailData) => {
     emailjs
       .send('service_gz8ssaq', 'RK_HAIRS', emailData, 'mSoyVaynP2tcN9E5R')
       .then(
       (response) => {
          console.log('Thankyou for shopping!email sent successfully', response.status, response.text, emailData);
          
         },
         (error) => {
         console.error('Email sending failed:', error);
          
       }
       );
      window.location.reload();

    
};





  const sendCheckoutDetails = () => {
    
    //console.log('Checkout details:', { name, contactNumber, email, cartItems });
  };

  const isValidEmail = (value) => {
    // Basic email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };
  const handleToggleCart = () => {
    if (cartItems.length > 0) {
      setShowCart(!showCart);
      if (!showCart) {
        document.body.classList.add('cart-open');
      } else {
        document.body.classList.remove('cart-open');
      }
    }
  };
  const handleProductChange = (product) => {
    setSelectedProduct(product);
    setSelectedColor("Natural");
    setSelectedSize("");
    setSelectedStructure("STRAIGHT"); 
    setUniqueProducts([product]);
    setUniqueSizes([]);
  
    setShowSecondGrid(true);
    const structures = rk["Natural"].filter(item => item.PRODUCT === product).flatMap(item => item.STRUCTURE.split(', '));
    
    
    const defaultStructure = structures.includes("STRAIGHT") ? "STRAIGHT" : structures[0];
    setSelectedStructure(defaultStructure);
    
    
    setUniqueStructures(Array.from(new Set(structures)));
    
    
    handleColorChange("Natural");
    secondGridRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  
  

  const handleColorChange = (color) => {
    setSelectedColor(color);
    setSelectedSize("");
    
   
    const structures = rk[color]
      .filter(item => item.PRODUCT === selectedProduct)
      .flatMap(item => item.STRUCTURE.split(', '));

   
    const uniqueStructures = Array.from(new Set(structures));
    
    
    const defaultStructure = uniqueStructures.includes("STRAIGHT") ? "STRAIGHT" : uniqueStructures[0];
    setSelectedStructure(defaultStructure);
    
    
    setUniqueStructures(uniqueStructures);
  };
  
  
  
  
  const handleStructureChange = (structure) => {
    setSelectedStructure(structure);
    
    const sizes = rk[selectedColor].filter(item => item.PRODUCT === selectedProduct && item.STRUCTURE.includes(structure)).map(item => item.SIZE);
    setUniqueSizes(Array.from(new Set(sizes)));
    if (isMobile) {
      const detailsTable = document.getElementById('details-table');
      if (detailsTable) {
        detailsTable.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };
  

  const handleSizeChange = (size) => {
    setSelectedSize(size);
    
    setSelectedQuantity(sizeQuantities[size] || 1);
  };
  
  const handleQuantityChange = (size, quantity) => {
    setSizeQuantities(prevQuantities => ({
      ...prevQuantities,
      [size]: quantity 
    }));
    
    if (size === selectedSize) {
      setSelectedQuantity(quantity);
    }
  };

  const getPrice = () => {
    if (!selectedColor || !selectedProduct || !selectedSize || !selectedStructure) return "";
    const product = rk[selectedColor].find(item => item.PRODUCT === selectedProduct && item.SIZE === parseInt(selectedSize) && item.STRUCTURE.includes(selectedStructure));
    if (!product) return "";
    return (selectedQuantity * product.totalPrice).toFixed(2);
  };

  const handleAddToCart = (item) => {
    const totalPrice = (sizeQuantities[item.SIZE] || 1) * item.PRICE;
    const totalWeight = (sizeQuantities[item.SIZE] || 1) * parseFloat(item['NET WT'].split(' ')[0]);
    const newItem = {
      color: selectedColor,
      product: selectedProduct,
      size: item.SIZE,
      structure: selectedStructure,
      quantity: sizeQuantities[item.SIZE] || 1,
      price: item.PRICE,
      netWeight: item['NET WT'],
      totalPrice: totalPrice.toFixed(2), 
      totalWeight: totalWeight.toFixed(2) 
    };
    setCartItems(prevItems => [...prevItems, newItem]);
    setShowDrawer(true);
    setShowCart(true);
    alert('Added To Cart Successfully!')
  };
  
  const handleDeleteFromCart = (index) => {
    setCartItems(prevItems => prevItems.filter((_, i) => i !== index));
  };
  
  const handleClearCart = () => {
    setCartItems([]);
    setShowCart(false);
    setGrandTotal(0);
    handleToggleDrawer(); 
  };
  
  const getTotalPrice = () => {
    return cartItems.reduce((total, item) => total + parseFloat(item.totalPrice), 0).toFixed(2);
  };
  
  

  if (!selectedProduct) {
    setSelectedProduct("MACHINE WEFT");
    setSelectedColor("Natural");
    setSelectedStructure('STRAIGHT')
}

const handleProductSelection = (product) => {
  setSelectedProduct(product);
  // If in mobile view, scroll to the cart section
  if (isMobile) {
    window.scrollTo({ top: document.getElementById('cart-section').offsetTop, behavior: 'smooth' });
  }
};
  
  return (
   
      <div>
        <Grid container spacing={2}>
          {/* First grid taking up 1/4 of the page width */}
          <Grid item xs={12} sm={3}>
          <div >
            {/* Content for the first grid */}
            <div style={{ textAlign: 'center' }}>
              <h3 style={{ marginBottom: '20px' }}>PRODUCTS</h3>
              <div className="product-container">
                {Object.keys(rk).flatMap(color => rk[color].map(item => item.PRODUCT)).filter((value, index, self) => self.indexOf(value) === index).map((product, index) => (
                  <div key={product} className="product-item">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <a href="#" onClick={() => handleProductChange(product)}>
                        <img
                          src={images.find(item => item.text === product)?.image || ''}
                          alt={`Product ${product}`}
                          style={{ width: '140px', height: '140px' }}
                        />
                      </a>
                      <button
                        style={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          marginTop: '10px',
                          backgroundColor: selectedProduct === product ? "blue" : "grey",
                          color: 'white',
                          border: 'none',
                          padding: '8px 16px',
                          cursor: 'pointer'
                        }}
                        onClick={() => handleProductChange(product)}
                      >
                        {product}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Grid>
          {/* Second grid taking up 2/4 of the page width */}
          <Grid item xs={12} sm={9} ref={secondGridRef}>
  <div style={{ position: "relative", width: "100%" }}>
    <div className='m-4'>
      {selectedProduct && (
        <>
          <div style={{ margin: '20px', textAlign: 'left' }}>
            <h2>Colors for <span style={{ color: 'red',fontSize:'24px' }}>{selectedProduct}</span></h2>
            <div style={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap' }}>
  {Object.keys(rk).filter(color => rk[color].some(item => item.PRODUCT === selectedProduct)).map(color => (
    <Button
      key={color}
      variant="contained"
      style={{ marginRight: '50px', marginBottom: '10px', backgroundColor: selectedColor === color ? "blue" : "grey", fontSize: '18px', color: 'white' }}
      onClick={() => handleColorChange(color)}
    >
      {color}
    </Button>
  ))}
</div>
          </div>
          {selectedColor && (
            <>
              <div style={{ margin: '20px', textAlign: 'left' }}>
                <h2>Structures for <span style={{ color: 'red',fontSize:'24px' }}>{selectedProduct}</span> and <span style={{ color: 'red' }}>{selectedColor.toUpperCase()}</span></h2>
                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                  {uniqueStructures.map((structure, index) => (
                    <Button
                      key={structure}
                      style={{
                        color: 'white',
                        backgroundColor: selectedStructure === structure ? "blue" : (selectedStructure && selectedStructure !== structure && index === 0 ? "grey" : "grey"),
                        marginRight: '10px',
                        marginBottom: '10px',
                        fontSize: '18px'
                      }}
                      onClick={() => handleStructureChange(structure)}
                    >
                      {structure}
                      </Button>
                  ))}
                </div>
              </div>
              {selectedStructure && (
                <div id="details-table" style={{ margin: '20px', textAlign: 'left' , overflowX: 'auto' }}>
                  <h2>Details for <span style={{ color: 'red',fontSize:'24px'  }}>{selectedStructure}</span><span style={{ color: 'red' }}>{selectedSize}</span> and <span style={{ color: 'red',fontSize:'24px'  }}>{selectedColor.toUpperCase()}</span></h2>
                  <TableContainer style={{ width: '100%', overflowX: 'auto' }}>
  <Table style={{ minWidth: 650 }}>
    <TableHead>
      <TableRow>
        <TableCell style={{ fontSize: '1rem', fontWeight: 'bold' }}>Size</TableCell>
        <TableCell style={{ fontSize: '1rem', fontWeight: 'bold' }}>Price</TableCell>
        <TableCell style={{ fontSize: '1rem', fontWeight: 'bold' }}>Net Weight</TableCell>
        <TableCell style={{ fontSize: '1rem', fontWeight: 'bold' }}>Quantity</TableCell>
        <TableCell style={{ fontSize: '1rem', fontWeight: 'bold' }}>Total Weight</TableCell>
        <TableCell style={{ fontSize: '1rem', fontWeight: 'bold' }}>Total Price</TableCell>
        <TableCell style={{ fontSize: '1rem', fontWeight: 'bold' }}>Action</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {rk[selectedColor]
        .filter(item => item.PRODUCT === selectedProduct && item.STRUCTURE.includes(selectedStructure) && item.STRUCTURE.split(', ').includes(selectedStructure))
        .map((item, index) => (
          <TableRow key={index}>
            <TableCell style={{ fontSize: '0.875rem', fontWeight: 'bold' }}>{item.SIZE}</TableCell>
            <TableCell style={{ fontSize: '0.875rem', fontWeight: 'bold' }}>${item.PRICE}</TableCell>
            <TableCell style={{ fontSize: '0.875rem', fontWeight: 'bold' }}>{item['NET WT']}</TableCell>
            <TableCell style={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
              <select style={{ fontSize: '0.875rem', fontWeight: 'bold' }} value={sizeQuantities[item.SIZE] || 1} onChange={(e) => handleQuantityChange(item.SIZE, parseInt(e.target.value))}>
                {[...Array(100).keys()].map(num => (
                  <option key={num + 1} value={num + 1}>{num + 1}</option>
                ))}
              </select>
            </TableCell>
            <TableCell style={{ fontSize: '1rem', fontWeight: 'bold' }}>
              {((sizeQuantities[item.SIZE] || 1) * parseFloat(item['NET WT'].split(' ')[0])).toFixed(2)} {item['NET WT'].split(' ')[1]}
            </TableCell>
            <TableCell style={{ fontSize: '1rem', fontWeight: 'bold' }}>${(sizeQuantities[item.SIZE] || 1) * item.PRICE}</TableCell>
            <TableCell>
              <Button
                variant="contained"
                color="success"
                onClick={() => handleAddToCart(item)}
              >
                Add
              </Button>
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  </Table>
</TableContainer>

                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  </div>
</Grid>
          {/* Third grid taking up 1/4 of the page width */}
          <Grid item xs={12} sm={1} style={{ border: '2px solid green' }}>
          {/* Right drawer for cart content */}

          <Drawer
  anchor="right"
  open={showDrawer}
  onClose={handleToggleDrawer}
  style={{ border: '2px solid green', overflowX: 'auto', overflowY: 'hidden' }}
  PaperProps={{
    style: {
      maxWidth: '100vw', // Adjusts maximum width of the drawer
    },
  }}
>
    
      {showCart && !showCheckoutForm && (
  <div className='m-4'>
    <div style={{ textAlign: 'center' }}>
      <div style={{ position: 'relative', marginBottom: '10px' }}>
        <div style={{ position: 'absolute', top: '10px', left: '10px', cursor: 'pointer', color: 'black' }} onClick={handleToggleDrawer}>
          <FaTimes size={30} />
        </div>
        <div style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', color: 'green' }} onClick={handleToggleDrawer}>
          <FaShoppingCart size={80} />
          <div style={{ position: 'absolute', top: '-8px', right: '-8px', backgroundColor: 'red', color: 'white', borderRadius: '50%', width: '20px', height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '0.75rem' }}>
            {cartItems.length}
          </div>
        </div>
      </div>
      <h2>Cart</h2>
    </div>
    <div style={{ overflowX: 'auto', overflowY: 'hidden', maxWidth: '100%', maxHeight: '70vh' }}>
      <TableContainer style={{ width: '100%' }}>
        <Table style={{ minWidth: 320 }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontSize: '1rem', fontWeight: 'bold' }}>Product</TableCell>
              <TableCell style={{ fontSize: '1rem', fontWeight: 'bold' }}>Color</TableCell>
              <TableCell style={{ fontSize: '1rem', fontWeight: 'bold' }}>Size</TableCell>
              <TableCell style={{ fontSize: '1rem', fontWeight: 'bold' }}>Structure</TableCell>
              <TableCell style={{ fontSize: '1rem', fontWeight: 'bold' }}>Quantity</TableCell>
              <TableCell style={{ fontSize: '1rem', fontWeight: 'bold' }}>Price</TableCell>
              <TableCell style={{ fontSize: '1rem', fontWeight: 'bold' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cartItems.map((item, index) => (
              <TableRow key={index}>
                <TableCell style={{ fontSize: '0.875rem', fontWeight: 'bold' }}>{item.product}</TableCell>
                <TableCell style={{ fontSize: '0.875rem', fontWeight: 'bold' }}>{item.color}</TableCell>
                <TableCell style={{ fontSize: '0.875rem', fontWeight: 'bold' }}>{item.size}</TableCell>
                <TableCell style={{ fontSize: '0.875rem', fontWeight: 'bold' }}>{item.structure}</TableCell>
                <TableCell style={{ fontSize: '0.875rem', fontWeight: 'bold' }}>{item.quantity}</TableCell>
                <TableCell style={{ fontSize: '0.875rem', fontWeight: 'bold' }}>${item.totalPrice}</TableCell>
                <TableCell>
                  <Button variant="contained" style={{ backgroundColor: 'red', color: 'white', fontSize: '0.75rem' }} onClick={() => handleDeleteFromCart(index)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan="5"><strong>Total Price:</strong></TableCell>
              <TableCell><strong>${getTotalPrice()}</strong></TableCell>
              <TableCell colSpan="2" style={{ textAlign: 'center' }}>
                {cartItems.length > 0 && (
                  <Button variant="contained" color="primary" onClick={() => setShowCheckoutForm(true)} style={{ fontSize: '0.75rem', marginRight: '5px' }}>Checkout</Button>
                )}
                <Button variant="contained" color="success" onClick={handleContinueShopping} style={{ fontSize: '0.75rem' }}>Continue Shopping</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    <Button variant="contained" color="primary" onClick={handleClearCart} style={{ textAlign: 'center', width: '100%', marginTop: '10px', fontSize: '0.75rem' }}>
      Clear Cart
    </Button>
  </div>
)}




{showCheckoutForm && (
  <Dialog
    open={showCheckoutForm}
    onClose={() => setShowCheckoutForm(false)}
    style={{ minWidth: '300px', maxWidth: '600px', textAlign: 'center', margin: 'auto', }}
    PaperProps={{
      style: {
        width: '90%',
        maxWidth: '600px',
        margin: '20px auto',
      },
    }}
  >
    <DialogTitle>Checkout</DialogTitle>
    <DialogContent>
      <form>
        {/* Checkout form fields */}
        <div className="row">
          <div className="col-md-6">
            <TextField
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
          </div>
          <div className="col-md-6">
            <TextField
              label="Contact Number"
              variant="outlined"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
          </div>
        </div>
        <div className="col-md-6">
          <TextField
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
            margin="normal"
          />
        </div>
      </form>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setShowCheckoutForm(false)} color="primary">
        Cancel
      </Button>
      <Button onClick={handleConfirmCheckout} color="primary">
        Submit
      </Button>
    </DialogActions>
  </Dialog>
)}

     
    </Drawer>
   
    <div style={{ position: 'fixed', top: '120px', right: '15px', cursor: 'pointer', color: 'green' }} onClick={handleToggleDrawer}>
            <FaShoppingCart size={40} />
            <div style={{ position: 'fixed', top: '115px', right: '5px', backgroundColor: 'red', color: 'white', borderRadius: '50%', width: '15px', height: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {cartItems.length}
            </div>
          </div>
         
        </Grid>
    

      
        </Grid>
      </div>
    );
  };
  

export default Shop;
